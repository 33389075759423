<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <img src="@/assets/qy.png" class="icon" />
            <input type="text" v-model="zonename" class="input" placeholder="请输入区域名称" />
          </div>
          <!--<div class="item">
            <img src="@/assets/lc.png" class="icon" />
            <input type="number" v-model="remark" class="input" placeholder="请输入最高楼层" />
          </div>-->
        </div>
        <div class="button primary mt" @click="submit">提交</div>
        <div class="button1 primary mt" @click="goback">取消</div>    
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  created() {
    this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.srole = this.$route.query.srole;
  },
  data() {
    return {
      type: '',
      unionid: '',
      srole: true,
      zonename: '',
      state:false,
      remark: ''
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/boss/region_list', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
    },
    async submit() {
      if(this.state){
        return false;
      }
      this.state = true;
      if (isEmpty(this.zonename)) {
        this.state = false;
        Toast('区域名称不可以为空!');
        return;
      }
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_addzone',
        qs.stringify({ zonename: this.zonename })
      );
      if (data.flag === '0') {
        this.state = false;
        Toast(data.message);
        this.$router.replace({path:'/boss/region_list', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
      } else {
        this.state = false;
        Dialog({ message: data.message });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.content {
  margin-top: 60px;
}
.container {
  padding: 0px 0px 20px 0px;
}
.button1 {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary {
    background: linear-gradient(90deg, rgba(31, 214, 199, 1) 0%, rgba(16, 189, 241, 1) 100%);
    color: #fff;
  }
  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
}
</style>